import axios from "axios";
import mixpanel from "mixpanel-browser";
import mainStore from "./MainStore/store";

// webengage events - enabled for prod
export const we_track = (ev_name, ev_data) => {
  if (process.env.NODE_ENV === "production") {
    try {
      if (ev_data) {
        mixpanel.track(ev_name, ev_data);
      } else {
        mixpanel.track(ev_name);
      }
    } catch (er) {}
  }
};
export const we_login = (userid) => {
  if (process.env.NODE_ENV === "production") {
    try {
      mixpanel.identify(userid);
    } catch (er) {}
  }
};

export const we_logout = () => {
  if (process.env.NODE_ENV === "production") {
    try {
      mixpanel.reset();
    } catch (er) {}
  }
};

export const we_set_user_attributes = (attributes) => {
  if (process.env.NODE_ENV === "production") {
    try {
      // set mixpanel profile
      const mixPanelAttributes = { ...attributes };
      const email = mixPanelAttributes.we_email;
      delete mixPanelAttributes.we_email;
      mixPanelAttributes["$email"] = email;
      mixpanel.people.set(mixPanelAttributes);
    } catch (er) {}
  }
};

export const bytesToSize = (bytes) => {
  if (bytes === null || bytes === undefined) return null;
  if (bytes < 1) return "0KB";
  const units = ["byte", "KB", "MB", "GB", "TB"];

  // const navigatorLocal =
  //   navigator.languages && navigator.languages.length >= 0 ? navigator.languages[0] : "en-US";
  // const unitIndex = Math.max(
  //   0,
  //   Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), units.length - 1)
  // );
  // unnecessary Intl use
  // return Intl.NumberFormat(navigatorLocal, {
  //   style: "unit",
  //   unit: units[unitIndex]
  // }).format((bytes / 1024 ** unitIndex).toFixed(1));
  const unitIndex = bytes >= 1 ? Math.floor(Math.log(bytes) / Math.log(1024)) : 1;
  return `${(bytes / Math.pow(1024, unitIndex)).toFixed(0)} ${units[unitIndex]}`;
};

export const copyText = (value) => {
  const { isWebView } = mainStore?.getState()?.uiStates;
  isWebView
    ? window?.ReactNativeWebView?.postMessage(`copy -${value}`)
    : window?.navigator?.clipboard?.writeText(`${value}`);
  mainStore.dispatch({
    type: "uiStates/showAlert",
    alertType: "info",
    payload: "Copied to Clipboard"
  });
};

export const validateLinkRegex = (input_link) => {
  // Regular expression to match URLs with or without protocol
  const http_regex = /^http[s]?\:\/\/+/g;
  const link_regex = /^[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,10}(\/\S*)?$/g;

  // Lowercase the input link
  let linkVal = input_link.toLowerCase();

  // Remove protocol prefix
  linkVal = linkVal.replace(http_regex, "");

  // Check if the link matches the regular expression
  const validLink = linkVal.match(link_regex);

  return validLink;
};
var user_ip_address = null;
export const getIPAddress = async () => {
  if (user_ip_address) {
    return user_ip_address;
  }
  const response = await axios.get("https://api.ipify.org?format=json");
  const data = response.data;
  user_ip_address = data?.ip;
  return data?.ip;
};

// export const validateLinkRegex = (input_link) => {
//   // Regular expression to match URLs with or without protocol
//   const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;

//   // Test the input link against the regular expression
//   const isValid = urlRegex.test(input_link);

//   return isValid;
// };
// export const getProxyURL = (baseURL) => {
//   const newURL = new URL(process.env.REACT_APP_PROXY_URL);
//   newURL.searchParams.set("image_url", baseURL);
//   return newURL.toString();
// };

export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};
