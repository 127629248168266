//@ts-nocheck
import { createTheme } from "./designSystem";
import CssBaseline from "./designSystem/CssBaseline";

import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { ThemeProvider } from "./designSystem";
import i18next from "i18next";
import mixpanel from "mixpanel-browser";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom";
import "../src/i18n";
import ErrorBoundary from "./ErrorBoundary";
import MainRouter from "./MainRouter";
import mainStore from "./MainStore/store";
import newTheme from "./designSystem/NewTheme";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { SENTRY_RELEASE_VERSION } from "./utils/constants";
// push lock test
const THEME = createTheme({
  typography: {
    fontFamily: `"Open Sans", sans-serif`,
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: "0px 4px 0px 6px"
        },
        input: {
          padding: "0px 4px 0px 6px"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#4c4d52",
          fontWeight: 500,
          fontSize: "14px"
        },
        asterisk: {
          color: "#E41F00"
        }
      }
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: "gradient" },
          style: {
            background: "linear-gradient(270.01deg, #D42C5A 33.09%, #286CD6 55.2%)",
            height: "2px"
          }
        }
      ]
    }
  },
  palette: {
    text: {
      primary: "#4c4d52"
    },
    primary: {
      main: "#296bd6",
      background: "#DDEAFF"
    },
    secondary: {
      main: "#4c4d52",
      light: "#6f7074"
    },
    background: {
      primary: "#E5EDFB",
      light: "#fafafa"
    }
  }
});

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://9cd8b0574c7d4fb29d75c235f09acad5@o4504710535708672.ingest.sentry.io/4504710538330112",
    // enable error tracing and replays
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    initialScope: {
      user: {
        id: mainStore.getState().globalReducer.username,
        email: mainStore.getState().globalReducer.username
      }
    },
    replaysSessionSampleRate: 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.5,
    allowUrls: ["app-test.predis.ai", "app.predis.ai"],
    beforeSend: (event, hint) => {
      const { originalException } = hint;
      // do not sent event in case of 401 / session expired
      if (
        originalException?.name === "AxiosError" &&
        (originalException?.response?.status === 401 || originalException?.response?.status === 426)
      )
        return;
      return event;
    },
    release: SENTRY_RELEASE_VERSION
  });
}

// init mixpanel
mixpanel.init("fef172c942435d527f1827e7aa526e63", {
  debug: process.env.NODE_ENV === "development" ? true : false,
  // track_pageview: true,
  persistence: "localStorage"
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    {/* <SideNavBar />
      <ContentAnalysis /> */}
    {/* <ThemeProvider theme={THEME}></ThemeProvider> */}
    <ThemeProvider theme={newTheme}>
      <CssBaseline />
      <I18nextProvider i18n={i18next}>
        <Provider store={mainStore}>
          <ErrorBoundary>
            <BrowserRouter>
              <MainRouter />

              {/* <CanvasApp/> */}
            </BrowserRouter>
          </ErrorBoundary>
        </Provider>
      </I18nextProvider>
    </ThemeProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
